// Подключаем GSAP и ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

// Главный таймлайн для анимаций на странице
let herotl = gsap.timeline();

herotl
  .from('.hero__title', { opacity: 0, x: -150, duration: 1, delay: 0.5 })
  .from('.hero__discr', { opacity: 0, x: -150, duration: 1, delay: 0.2 }, "-=1")
  .from('.hero__subtitle', { opacity: 0, duration: 0.5, delay: 0.5 }, "-=1")
  .from('.container__btn', { opacity: 0, duration: 0.5, delay: 0.5 }, "-=1")
  .from('.hero__img', { opacity: 0, x: 150, duration: 1, delay: 1 }, "-=1")
  .from('.hero__lower', { opacity: 0, y: 0, duration: 1.2, delay: 1.5 }, "-=1");



// Таймлайн для анимации блока .advantage
const infomiddle = gsap.timeline({
  scrollTrigger: {
    trigger: '.advantage',
    start: 'top center',
    once: true
  }
});

// Анимации для различных элементов внутри .advantage
infomiddle.from('.advantage__upper', { opacity: 0, y: 15, duration: 2 })
  .from('.advantage__block', { opacity: 0, y: 15, duration: 2, stagger: 0.2 }, "-=0.5")
  .from('.advantage__item-title, .advantage__item-sutitle', { autoAlpha: 0, y: 30, duration: 3, stagger: 0.2 }, "-=1");

// Дополнительный ScrollTrigger для анимации элементов внутри .advantage при приближении к верху экрана
ScrollTrigger.batch('.advantage__item-title, .advantage__item-sutitle', {
  onEnter: batch => gsap.to(batch, { autoAlpha: 1, y: 0, stagger: 0.4, overwrite: true }),
  start: 'top center',
  once: true
});


// Таймлайн для анимации блока .advantage
const info = gsap.timeline({
  scrollTrigger: {
    trigger: '.info',
    start: 'top center',
    once: true,

  }
});

// Анимации для различных элементов внутри .advantage
info.from('.info__lower-title', { opacity: 0, y: 75, duration: 1 })
  .from('.info__main-block, .info__project-list', { opacity: 0, y: 75, duration: 2, stagger: 0.4, delay: 0.2 }, "-=1")
  .from('.info__lower-block', { opacity: 0, y: 75, duration: 1, delay: 0.3 }, "-=0.5")







// Таймлайн для анимации блока .services
const servicesTimeline = gsap.timeline({
  scrollTrigger: {
    trigger: '.services',
    start: 'top center', // Момент старта анимации при скролле
    once: true, // Выполнить анимацию только один раз

  }
});

// Анимации для различных элементов внутри .services
servicesTimeline
  .from('.services__title', { opacity: 0, y: 75, duration: 1 }) // Анимация для заголовка
  .from('.services__item', { opacity: 0, y: 75, duration: 1, stagger: 0.2, delay: 0.2 }, "<") // Анимация для элементов услуг с задержкой
  .from('.services__container-block', { opacity: 0, y: 105, duration: 2, stagger: 0.5 }, "<1.5"); // Анимация для блоков внутри .services


gsap.registerPlugin(ScrollTrigger);

// Анимация для заголовка
gsap.from('.ceo__title', {
  scrollTrigger: {
    trigger: '.ceo__title',
    start: 'top bottom', // начало анимации, когда верх элемента входит в область просмотра
    toggleActions: 'play none none none',

  },
  opacity: 0,
  y: 30,
  duration: 1
});

// Анимация для описания
gsap.from('.ceo__discr', {
  scrollTrigger: {
    trigger: '.ceo__discr',
    start: 'top bottom',
    toggleActions: 'play none none none',
  },
  opacity: 0,
  y: 30,
  duration: 2,
  delay: 0.4 // небольшая задержка, чтобы анимация начиналась после анимации заголовка
});

// Анимация для кнопок
gsap.from('.ceo__button', {
  scrollTrigger: {
    trigger: '.ceo__button',
    start: 'top bottom',
    toggleActions: 'play none none none',
  },
  opacity: 0,
  y: 30,
  duration: 2,
  stagger: 0.2, // задержка между анимациями кнопок
  delay: 0.4 // задержка, чтобы кнопки начали анимироваться после заголовка и описания
});


/*burger menu */
// Обработчик клика по кнопке бургера
document.querySelector('.burger').addEventListener('click', function () {
  const headerLower = document.querySelector('.header__lower');
  headerLower.classList.toggle('active');

  if (headerLower.classList.contains('active')) {
    // Применяем GSAP анимацию
    gsap.from('.header__lower-link', {
      duration: 0.5, // продолжительность в секундах
      opacity: 0,    // начальная прозрачность
      x: -20,        // начальное положение по оси Y
      stagger: 0.2,  // задержка между анимациями каждой ссылки
      ease: "power1.out" // тип плавности анимации
    });


  }
});

